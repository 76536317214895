import React, {useRef, useState, useEffect} from 'react';
import {Button, Form, Image, Input} from 'antd';
import {mobXStore} from "../../store";

import Error from '../Notifications/Error/Error';

import Logo from '../../bot_logo_small.png';
import s from './LoginForm.module.scss';
import PropTypes from "prop-types";

function LoginForm({api}) {

    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [errTimeout, setErrTimeout] = useState(null);

    const inputRef = useRef();

    useEffect(() => {
        inputRef.current.focus({
            cursor: 'start',
        });

        return () => {
            setErrorMsg('');
            setLoading(false);
            if (errTimeout !== null) {
                clearTimeout(errTimeout);
            }
        }
    });


    // ввели логин и пароль - проверяем
    function onFinish({login, password}) {
        // Get Login Info

        setLoading(true);
        setErrorMsg('');

        api.login(login, password).then((loginInfo) => {

            setLoading(false);
            if (loginInfo.result) {
                mobXStore.setKey('isLogin', true);
                mobXStore.setKey('current_user', login);
            } else {
                handlerSetErrorMsg(loginInfo.error);
                mobXStore.setKey('isLogin', false);
            }

        }).catch((error) => {
            setLoading(false);
            handlerSetErrorMsg(error.message);
            mobXStore.setKey('isLogin', false);
        });
    }

    // Выводим ошибку
    function handlerSetErrorMsg(error) {
        if (error !== '') {
            setErrorMsg(error);

            setErrTimeout(setTimeout(() => {
                setErrorMsg('');
            }, 5000));
        }
    }

    // Отрисовка формы
    function renderForm() {

        const layout = {
            labelCol: {span: 5}, wrapperCol: {span: 18},
        };
        const tailLayout = {
            wrapperCol: {offset: 5},
        };

        const validateMessages = {
            // eslint-disable-next-line no-template-curly-in-string
            required: '${label} - required field!',
        };

        return (<div>
            <Form
                {...layout}
                name="basic"
                initialValues={{remember: true}}
                onFinish={onFinish}
                validateMessages={validateMessages}
            >
                <Form.Item
                    label="Имя"
                    name="login"
                    rules={[{required: true, min: 5, max: 30, message: 'Ввведите Логин (5-30 символов)'}]}
                >
                    <Input
                        ref={inputRef}
                    />
                </Form.Item>

                <Form.Item
                    label="Пароль"
                    name="password"
                    rules={[{required: true, min: 5, max: 30, message: 'Ввведите Пароль (5-30 символов)'}]}
                >
                    <Input.Password
                        visibilityToggle={true}
                    />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" loading={loading}
                            style={{width: '80%', justifyContent: 'center'}}>
                        Войти
                    </Button>
                </Form.Item>
            </Form>
        </div>);
    }

    return (<div>
        <Error
            message={errorMsg}
        />
        <div className={s.login_logo}>
            <Image
                src={Logo}
                width={200}
                preview={false}
                alt=""
            />
        </div>
        <div className={s.root}>
            <div className={s.form_wrap}>
                {renderForm()}
            </div>
        </div>
    </div>);
}

LoginForm.propTypes = {
    api: PropTypes.object.isRequired,
};

export default LoginForm;
