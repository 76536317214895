import React, {memo, useEffect, useLayoutEffect, useState} from 'react';
import {Button, Card, Col, Collapse, DatePicker, Row, Select, Space, Statistic, Tabs, Tooltip} from "antd";

import ProfitChartBar from "../ProfitChartBar";
import ProfitTable from "../ProfitTable";
import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import moment from "moment/moment";
import 'dayjs/locale/ru';

import {mobXStore} from "../../store";
import ProfitChartLine from "../ProfitChartLine";
import {ReloadOutlined} from "@ant-design/icons";
import PropTypes from "prop-types";
import TopSymbolTable from "../TopSymbolTable";

const {Panel} = Collapse;
const {RangePicker} = DatePicker;

dayjs.extend(updateLocale)
dayjs.updateLocale('ru', {
    weekStart: 1
})

const select_strategy_options = [
    {
        label: 'Все стратегии',
        value: 0,
    },
    {
        label: 'Pair Trading TF 1h',
        value: 1,
    },
    {
        label: 'Pair Trading TF 1D',
        value: 4,
    },
    {
        label: 'LinearRegression TF 1h',
        value: 2,
    },
    {
        label: 'VidkatShort TF 5m',
        value: 6,
    },
    {
        label: 'VidkatOne SHORT TF 5m',
        value: 7
    },
    {
        label: 'VidkatOne LONG TF 5m',
        value: 8
    },
    {
        label: 'VidkatOne SHORT TF 15m',
        value: 9
    },
    {
        label: 'VidkatOne LONG TF 15m',
        value: 10
    }
    // {
    //     label: 'StrategyRSI Test TF 1h',
    //     value: 3,
    // },
    // {
    //     label: 'DCA TF 1h',
    //     value: 5,
    // }
]

const dateRangePeriods = [
    {
        label: 'Сегодня',
        value: [dayjs(), dayjs()],
    },
    {
        label: 'Вчера',
        value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')],
    },
    {
        label: 'Эта неделя',
        value: [dayjs().startOf('week').add(1, 'd'), dayjs().endOf('week').add(1, 'd')],
    },
    {
        label: 'Прошлая неделя',
        value: [dayjs().add(-1, 'week').startOf('week').add(1, 'd'), dayjs().add(-1, 'week').endOf('week').add(1, 'd')],
    },
    {
        label: 'Этот месяц',
        value: [dayjs().startOf('month'), dayjs().endOf('month')],
    },
    {
        label: 'Прошлый месяц',
        value: [dayjs().add(-1, 'month').startOf('month'), dayjs().add(-1, 'month').endOf('month')],
    },
    {
        label: 'Последние 7 дней',
        value: [dayjs().add(-6, 'd'), dayjs()],
    },
    {
        label: 'Последние 10 дней',
        value: [dayjs().add(-9, 'd'), dayjs()],
    },
    {
        label: 'Последние 15 дней',
        value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
        label: 'Последние 30 дней',
        value: [dayjs().add(-29, 'd'), dayjs()],
    },
    {
        label: 'Последние 90 дней',
        value: [dayjs().add(-89, 'd'), dayjs()],
    },
    {
        label: 'Последние 180 дней',
        value: [dayjs().add(-179, 'd'), dayjs()],
    },
    {
        label: 'Последние 365 дней',
        value: [dayjs().add(-364, 'd'), dayjs()],
    }
];


function StatisticsBlock({api, setErrorMsg}) {

    // const windowHeight = window.innerHeight
    const [loading, setLoading] = useState(false);
    const [errTimeout, setErrTimeout] = useState(0); // таймаут для вывода ошибки
    const [exitTimeData, setExitTimeData] = useState(false);
    const [profitsStrategies, setProfitsStrategies] = useState(false);
    const [profitsDirectionStrategies, setProfitsDirectionStrategies] = useState(false);
    const [profitsSymbolStrategies, setProfitsSymbolStrategies] = useState(false);
    const [profitsSymbolsStatsTop, setProfitsSymbolsStatsTop] = useState(false);
    const [entryTimeData, setEntryTimeData] = useState(false);
    const [exitTimeDataCut, setExitTimeDataCut] = useState(false);
    const [entryTimeDataCut, setEntryTimeDataCut] = useState(false);

    const [lastErrMsg, setLastErrMsg] = useState('');

    const [pnlToday, setPnlToday] = useState(0);
    const [pnl7day, setPnl7day] = useState(0);
    // const [pnl15day, setPnl15day] = useState(0);
    const [pnl30day, setPnl30day] = useState(0);
    // const [pnl90day, setPnl90day] = useState(0);
    const [pnlNday, setPnlNday] = useState(0);

    const [startReportDate, setStartReportDate] = useState(dayjs().add(-6, 'd'));
    const [endReportDate, setEndReportDate] = useState(dayjs());

    const [strategySelectValue, setStrategySelectValue] = useState(0);

    const loadData = () => {
        get_strategies_pnl_stats_result()
        get_strategies_directions_pnl_stats_result()
        get_strategies_symbol_pnl_stats_result()
        get_profit_by_exit_time_result()
        get_profit_by_entry_time_result()
        get_top_symbols_profit_loss()
        setPnlNday(calculatePnLPeriod(exitTimeData, startReportDate, endReportDate));
    }

    useEffect(() => {

        // Обновляем данные каждую минуту
        // const timerRefresh = setTimeout(() => {
        //     loadData()
        // }, 60000);

        return () => {
            setErrorMsg('');
            if (errTimeout !== null) {
                clearTimeout(errTimeout);
                // clearTimeout(timerRefresh);
            }
        }
    }, []);

    useEffect(() => {
        loadData()
    }, [startReportDate, endReportDate, strategySelectValue]);

    useLayoutEffect(() => {
        try {
            setExitTimeDataCut(filterDataByDate(exitTimeData, startReportDate.format('YYYY-MM-DD'), endReportDate.format('YYYY-MM-DD')));
            setEntryTimeDataCut(filterDataByDate(entryTimeData, startReportDate.format('YYYY-MM-DD'), endReportDate.format('YYYY-MM-DD')));
            setPnlNday(calculatePnLPeriod(exitTimeData, startReportDate.format('YYYY-MM-DD'), endReportDate.format('YYYY-MM-DD')));
        } catch (e) {
            setExitTimeDataCut(filterDataByDate(exitTimeData, startReportDate, endReportDate));
            setEntryTimeDataCut(filterDataByDate(entryTimeData, startReportDate, endReportDate));
            setPnlNday(calculatePnLPeriod(exitTimeData, startReportDate, endReportDate));
        }
    }, [entryTimeData, exitTimeData]);

    // Фільтрація даних за заданим періодом
    const filterDataByDate = (data, startDate, endDate) => {
        if (!data) return [];

        // Перетворення строкових дат у об'єкти moment для зручного порівняння
        const startMoment = moment(startDate, 'YYYY-MM-DD');
        const endMoment = moment(endDate, 'YYYY-MM-DD');

        // Фільтрація даних
        return data.filter(item => {
            let entryTime;
            if (item.exit_time) {
                entryTime = moment(item.exit_time, 'YYYY-MM-DD');
            } else {
                entryTime = moment(item.entry_time, 'YYYY-MM-DD');
            }
            return entryTime.isSameOrAfter(startMoment) && entryTime.isSameOrBefore(endMoment);
        });
    };

    // Выводим ошибку
    function handlerSetErrorMsg(error) {
        if (error !== '' && error !== lastErrMsg) {
            setLastErrMsg(error);

            setErrorMsg(error);

            setErrTimeout(setTimeout(() => {
                setErrorMsg('');
            }, 5000));
        }
    }

    // статистика PnL по времени выхода из позиции
    function get_profit_by_exit_time_result() {
        // Get Login Info
        setLoading(true);
        setErrorMsg('');

        api.get_profit_by_exit_time(strategySelectValue).then((result) => {

            setLoading(false);

            if (result.result) {
                setExitTimeData(result.data);
                setExitTimeDataCut(filterDataByDate(result.data, startReportDate, endReportDate));

                setPnlToday(calculatePnL(result.data, 1));
                setPnl7day(calculatePnL(result.data, 7));
                // setPnl15day(calculatePnL(result.data, 15));
                setPnl30day(calculatePnL(result.data, 30));
                setPnlNday(calculatePnLPeriod(result.data, startReportDate, endReportDate));

            } else {
                handlerSetErrorMsg(result.error);

                if (result.action === 'logout') {
                    // handlerSetErrorMsg('Сессия закончилась');
                    setTimeout(() => {
                        mobXStore.setKey('isLogin', false);
                    }, 1000);
                }
            }

        }).catch((error) => {
            setLoading(false);
            handlerSetErrorMsg(error.message);
        });
    }

    // статистика PnL по времени выхода из позиции
    function get_profit_by_entry_time_result() {
        // Get Login Info
        setLoading(true);
        setErrorMsg('');

        api.get_profit_by_entry_time(strategySelectValue).then((result) => {

            setLoading(false);

            if (result.result) {
                setEntryTimeData(result.data);
                setEntryTimeDataCut(filterDataByDate(result.data, startReportDate, endReportDate));
            } else {
                handlerSetErrorMsg(result.error);

                if (result.action === 'logout') {
                    // handlerSetErrorMsg('Сессия закончилась');
                    setTimeout(() => {
                        mobXStore.setKey('isLogin', false);
                    }, 1000);
                }
            }

        }).catch((error) => {
            setLoading(false);
            handlerSetErrorMsg(error.message);
        });
    }

    // статистика PnL по времени выхода из позиции
    function get_strategies_pnl_stats_result() {
        // Get Login Info
        setLoading(true);
        setErrorMsg('');

        api.get_strategies_pnl_stats(strategySelectValue, startReportDate, endReportDate).then((result) => {

            setLoading(false);

            if (result.result) {
                setProfitsStrategies(result.data);
            } else {
                handlerSetErrorMsg(result.error);

                if (result.action === 'logout') {
                    // handlerSetErrorMsg('Сессия закончилась');
                    setTimeout(() => {
                        mobXStore.setKey('isLogin', false);
                    }, 1000);
                }
            }

        }).catch((error) => {
            setLoading(false);
            handlerSetErrorMsg(error.message);
        });
    }

    // статистика PnL по монетам
    function get_top_symbols_profit_loss() {
        // Get Login Info
        setLoading(true);
        setErrorMsg('');

        api.get_top_symbols_profit_loss(strategySelectValue, startReportDate, endReportDate).then((result) => {

            setLoading(false);

            if (result.result) {
                setProfitsSymbolsStatsTop(result.data);
            } else {
                handlerSetErrorMsg(result.error);

                if (result.action === 'logout') {
                    // handlerSetErrorMsg('Сессия закончилась');
                    setTimeout(() => {
                        mobXStore.setKey('isLogin', false);
                    }, 1000);
                }
            }

        }).catch((error) => {
            setLoading(false);
            handlerSetErrorMsg(error.message);
        });
    }

    // статистика PnL по времени выхода из позиции
    function get_strategies_directions_pnl_stats_result() {
        // Get Login Info
        setLoading(true);
        setErrorMsg('');

        api.get_direction_pnl_stats(strategySelectValue, startReportDate, endReportDate).then((result) => {

            setLoading(false);

            if (result.result) {
                setProfitsDirectionStrategies(result.data);
            } else {
                handlerSetErrorMsg(result.error);

                if (result.action === 'logout') {
                    // handlerSetErrorMsg('Сессия закончилась');
                    setTimeout(() => {
                        mobXStore.setKey('isLogin', false);
                    }, 1000);
                }
            }

        }).catch((error) => {
            setLoading(false);
            handlerSetErrorMsg(error.message);
        });
    }

    // статистика PnL по времени выхода из позиции
    function get_strategies_symbol_pnl_stats_result() {
        // Get Login Info
        setLoading(true);
        setErrorMsg('');

        api.get_symbol_pnl_stats(strategySelectValue, startReportDate, endReportDate).then((result) => {

            setLoading(false);

            if (result.result) {
                setProfitsSymbolStrategies(result.data);
            } else {
                handlerSetErrorMsg(result.error);

                if (result.action === 'logout') {
                    // handlerSetErrorMsg('Сессия закончилась');
                    setTimeout(() => {
                        mobXStore.setKey('isLogin', false);
                    }, 1000);
                }
            }

        }).catch((error) => {
            setLoading(false);
            handlerSetErrorMsg(error.message);
        });
    }

    // расчет PnL за последние N дней
    const calculatePnL = (data, days) => {

        if (!data) return 0;

        const lastNDays = moment().subtract(days, 'days');
        let sumPNL = 0;

        data.forEach(item => {
            const entryTime = moment(item.exit_time, 'YYYY-MM-DD');
            if (entryTime.isSameOrAfter(lastNDays)) {
                sumPNL += item.PNL;
            }
        });

        return Math.round(sumPNL * 100) / 100;
    }


    // Розрахунок PnL за заданий період
    const calculatePnLPeriod = (data, startDate, endDate) => {
        if (!data) return 0;

        let sumPNL = 0;

        // Перетворення строкових дат у об'єкти moment для зручного порівняння
        const startMoment = moment(startDate, 'YYYY-MM-DD');
        const endMoment = moment(endDate, 'YYYY-MM-DD');

        data.forEach(item => {
            const entryTime = moment(item.exit_time, 'YYYY-MM-DD');
            // Перевірка, чи знаходиться дата в межах заданого періоду
            if (entryTime.isSameOrAfter(startMoment) && entryTime.isSameOrBefore(endMoment)) {
                sumPNL += item.PNL;
            }
        });

        // Округлення до двох десяткових знаків
        return Math.round(sumPNL * 100) / 100;
    };

    return (
        <>
            <Row gutter={24} align={"middle"} justify={'center'} style={{background: '#efefef'}}>
                <Col>
                    <Space size={"large"} wrap={true} align={'center'}
                           style={{width: '100%', justifyContent: 'center'}}>
                        <Select
                            size={'large'}
                            defaultValue={strategySelectValue}
                            onChange={(value) => {
                                if (value !== null) {
                                    setStrategySelectValue(value);
                                }
                            }}
                            style={{
                                width: 300,
                            }}
                            options={select_strategy_options}
                        />
                        <RangePicker
                            size={'large'}
                            locale={locale}
                            // disabled={!showDataRange}
                            // style={{marginBottom: '12px'}}
                            presets={dateRangePeriods}
                            style={{
                                width: 300,
                            }}
                            onChange={(dates) => {
                                if (dates !== null) {
                                    setStartReportDate(dates[0].format('YYYY-MM-DD'));
                                    setEndReportDate(dates[1].format('YYYY-MM-DD'));
                                }
                            }}
                            value={[dayjs(startReportDate, 'YYYY-MM-DD'), dayjs(endReportDate, 'YYYY-MM-DD')]}
                            defaultValue={[dayjs().add(-6, 'd'), dayjs()]}
                        />

                        <Tooltip title="Обновить данные">
                            <Button
                                size={'large'}
                                onClick={() => {
                                    loadData()
                                }}
                                icon={<ReloadOutlined/>}
                                style={{color: '#617af8'}}
                            />
                        </Tooltip>
                    </Space>
                </Col>
            </Row>

            <Collapse defaultActiveKey={['1', '2']} bordered={true}>
                <Panel header={<b>Графики</b>} key="1">
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={24} align={"center"}>
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={12} lg={12}>
                                    <Card
                                        title={<h3>Прибыль/Убыток по периодам</h3>}>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Statistic title="сегодня" value={pnlToday}/>
                                            </Col>
                                            <Col span={12}>
                                                <Statistic title="за выбранный период" value={pnlNday}/>
                                            </Col>
                                            <Col span={12}>
                                                <br/>
                                                <Statistic title="за 7 дней" value={pnl7day}/>
                                            </Col>
                                            <Col span={12}>
                                                <br/>
                                                <Statistic title="за 30 дней" value={pnl30day}/>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12}>
                                    <Card
                                        title={<h3>Прибыль/Убыток по направлению</h3>}>
                                        <ProfitChartBar height={148} data={profitsDirectionStrategies}
                                                        date_field='side'/>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} align={"center"}>
                            <Card
                                title={<h3>Прибыль/Убыток по стратегиям</h3>}>
                                <ProfitChartBar height={150} data={profitsStrategies} date_field='name'/>
                            </Card>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} align={"center"}>
                            <Card
                                title={<h3>Прибыль/Убыток по времени закрытия сделок</h3>}>
                                <ProfitChartLine height={150} data={exitTimeDataCut} date_field='exit_time'/>
                            </Card>
                            {/*<Card>*/}
                            {/*    <ProfitChartBar data={exitTimeDataCut} date_field='exit_time'/>*/}
                            {/*</Card>*/}
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} align={"center"}>
                            <Card
                                title={<h3>Прибыль/Убыток по времени открытия сделок</h3>}>
                                <ProfitChartLine height={150} data={entryTimeDataCut} date_field='entry_time'/>
                            </Card>
                            {/*<Card>*/}
                            {/*    <ProfitChartBar data={exitTimeDataCut} date_field='exit_time'/>*/}
                            {/*</Card>*/}
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} align={"center"}>
                            <Card
                                title={<h3>Прибыль/Убыток по активам</h3>}>
                                <ProfitChartBar height={300} data={profitsSymbolStrategies} date_field='symbol'/>
                            </Card>
                        </Col>
                    </Row>
                </Panel>
                <Panel header={<b>Таблицы</b>} key="2">
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={24} xxl={24} align={"center"}>
                            <Tabs
                                type="card"
                                defaultActiveKey="1"
                            >
                                <Tabs.TabPane tab="Статистика по Дате Закрытия сделок" key="1">
                                    <ProfitTable
                                        loading={loading}
                                        data={exitTimeDataCut}
                                        date_field='exit_time'
                                        title={'По дате закрытия сделок'}/>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Статистика по Дате Открытия сделок" key="2">
                                    <ProfitTable
                                        loading={loading}
                                        data={entryTimeDataCut}
                                        date_field='entry_time'
                                        title={'По дате открытия сделок'}/>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Прибыль/Убыток по активам" key="3">
                                    <TopSymbolTable
                                        loading={loading}
                                        data={profitsSymbolsStatsTop}
                                        title={'Прибыль/Убыток по активам'}/>
                                </Tabs.TabPane>
                            </Tabs>
                        </Col>
                        {/*<Col xs={24} sm={24} md={24} lg={24} xxl={24} align={"center"}>*/}
                        {/*    /!*<Card>*!/*/}
                        {/*    /!*    <ProfitChartBar data={exitTimeDataCut} date_field='exit_time'/>*!/*/}
                        {/*    /!*</Card>*!/*/}
                        {/*    <Card>*/}
                        {/*        <TopSymbolTable*/}
                        {/*            loading={loading}*/}
                        {/*            data={profitsSymbolsStatsTop}*/}
                        {/*            title={'Прибыль/Убыток по активам'}/>*/}
                        {/*    </Card>*/}
                        {/*</Col>*/}
                        {/*<Col xs={24} sm={24} md={24} lg={24} xxl={12} align={"center"}>*/}
                        {/*    /!*<Card>*!/*/}
                        {/*    /!*    <ProfitChartBar data={exitTimeDataCut} date_field='exit_time'/>*!/*/}
                        {/*    /!*</Card>*!/*/}
                        {/*    <Card>*/}
                        {/*        <ProfitTable*/}
                        {/*            loading={loading}*/}
                        {/*            data={exitTimeDataCut}*/}
                        {/*            date_field='exit_time'*/}
                        {/*            title={'По дате закрытия сделок'}/>*/}
                        {/*    </Card>*/}
                        {/*</Col>*/}
                        {/*<Col xs={24} sm={24} md={24} lg={24} xxl={12} align={"center"}>*/}
                        {/*    <Card>*/}
                        {/*        <ProfitTable*/}
                        {/*            loading={loading}*/}
                        {/*            data={entryTimeDataCut}*/}
                        {/*            date_field='entry_time'*/}
                        {/*            title={'По дате открытия сделок'}/>*/}
                        {/*    </Card>*/}
                        {/*</Col>*/}
                    </Row>
                </Panel>
            </Collapse>
        </>
    )
}

StatisticsBlock.propTypes = {
    api: PropTypes.object.isRequired,
    setErrorMsg: PropTypes.func.isRequired,
};

export default memo(StatisticsBlock);