import {createChart} from 'lightweight-charts';
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {HistoricalData} from "../../Helpers/BinancelDataAPI";
import {calculateSMA, formatNumber} from "../../Helpers/statistics_function";
import PropTypes from "prop-types";
import moment from "moment";

const chartOptions = {
    autoSize: true,
    layout: {
        textColor: 'black',
        background: {type: 'solid', color: '#ffffff'},
    },
    rightPriceScale: {
        borderVisible: false,
    },
    crosshair: {
        // mode: LightweightCharts.CrosshairMode.Normal,
        vertLine: {
            color: '#6A5ACD',
            width: 1,
            style: 1,
            visible: true,
            labelVisible: true,
        },
        horzLine: {
            color: '#6A5ACD',
            width: 0.5,
            style: 1,
            visible: true,
            labelVisible: true,
        },
        mode: 0,
    },
    timeScale: {
        // rightOffset: 50,
        // barSpacing: 10,
        // fixLeftEdge: true,
        // lockVisibleTimeRangeOnResize: true,
        rightBarStaysOnScroll: true,
        // borderVisible: true,
        // borderColor: '#617af8',
        // visible: true,
        timeVisible: true,
        // secondsVisible: true,
    },
}

export const TVChartLight = forwardRef(({
                                            symbol = 'BTCUSDT',
                                            interval = '1d',
                                            openOrderTime = '',
                                            closeOrderTime = ''
                                        }, ref) => {

    let chart = null;
    const chartContainerRef = useRef();
    let handleResize = null;
    let candleSeries = null;
    let volumeSeries = null;

    let binanceWS = null;

    // let smaLineContainer = [];

    let legendPrice = null;
    let firstRow = null;

    const [chartSymbol, setChartSymbol] = useState(symbol);
    const [chartInterval, setChartInterval] = useState(interval);

    const openOrderTimeState = moment(openOrderTime,).add('1', 'hour').unix();
    const closeOrderTimeState = moment(closeOrderTime).add('1', 'hour').unix();

    // Функція для відключення WebSocket викликається з батьківського компонента
    useImperativeHandle(ref, () => ({
        disconnect_ws() {
            console.log('disconnect_ws');
            setChartSymbol('');
            setChartInterval('');

            if (binanceWS !== null) {
                binanceWS.close();
            }

            if (chart !== null) {
                chart.unsubscribeCrosshairMove(updateLegend);
                chart.removeSeries(candleSeries);
                chart.removeSeries(volumeSeries);
                chart.remove();
            }

            candleSeries = null;
            volumeSeries = null;

            window.removeEventListener('resize', handleResize);
        },

        setNewInterval(newInterval) {
            console.log('setNewInterval', newInterval);
            setChartInterval(newInterval);
        }
    }));

    // Функція для підключення WebSocket Binance
    const connectToWS = () => {
        console.log('connectToWS', chartSymbol, chartInterval);

        // Очищаємо графік
        addSeries();

        if (binanceWS !== null) {
            binanceWS.onmessage = null;
            binanceWS.close();
        }

        // Отримуємо історичні дані
        HistoricalData(chartSymbol, chartInterval, 1500)
            .then(rawData => convertData(rawData))
            .then(convertedData => {
                try {
                    if (convertedData !== null) {
                        const dd = convertedData //.slice(0, -1);

                        candleSeries.setData(dd);
                        volumeSeries.setData(dd);

                        console.log(dd[dd.length - 5])

                        const markers = [
                            {
                                time: openOrderTimeState,
                                position: 'aboveBar',
                                color: '#e91e63',
                                shape: 'arrowDown',
                                text: 'Open @ ' + openOrderTime,
                            }
                        ]

                        if (closeOrderTimeState >= openOrderTimeState) {
                            markers.push({
                                time: closeOrderTimeState,
                                position: 'belowBar',
                                color: '#2196F3',
                                shape: 'arrowUp',
                                text: 'Close @ ' + closeOrderTime,
                            })
                        }

                        console.log(markers)

                        candleSeries.setMarkers(markers)

                        // chart.resetTimeScale();

                        // for (const container of smaLineContainer) {
                        //     try {
                        //         chart.removeSeries(container);
                        //     } catch (e) {
                        //     }
                        // }

                        // smaLineContainer = [];
                        //
                        // smaLineContainer.push(createSMALineSeries(dd, 20, 'rgba(255, 0, 0, 1)'));
                        // smaLineContainer.push(createSMALineSeries(dd, 50, 'rgb(130,217,118)'));
                        // smaLineContainer.push(createSMALineSeries(dd, 100, 'rgb(97,122,248)'));
                        // smaLineContainer.push(createSMALineSeries(dd, 200, 'rgb(3,3,66)'));
                    }
                } catch (e) {
                    console.log(e);
                }

            });

        binanceWS = new WebSocket(`wss://fstream.binance.com/ws/${chartSymbol.toLowerCase()}@kline_${chartInterval}`);

        binanceWS.onmessage = (event) => {
            const message = JSON.parse(event.data);
            const candle = message.k;
            const update = {
                // time: moment(candle.t).format('YYYY-MM-DD'),
                time: candle.t / 1000,
                open: parseFloat(candle.o),
                high: parseFloat(candle.h),
                low: parseFloat(candle.l),
                close: parseFloat(candle.c),
                volume: parseFloat(candle.q),
                value: parseFloat(candle.q),
            };

            try {

                let precision = 6;
                let minMove = 0.000001;
                if (candle.o > 1) {
                    precision = 2;
                    minMove = 0.01;
                } else if (candle.o > 0.1) {
                    precision = 3;
                    minMove = 0.001;
                } else if (candle.o > 0.01) {
                    precision = 4;
                    minMove = 0.0001;
                } else if (candle.o > 0.001) {
                    precision = 5;
                    minMove = 0.00001;
                } else if (candle.o > 0.0001) {
                    precision = 6;
                    minMove = 0.000001;
                } else if (candle.o > 0.00001) {
                    precision = 7;
                    minMove = 0.0000001;
                } else if (candle.o > 0.000001) {
                    precision = 8;
                    minMove = 0.00000001;
                } else if (candle.o > 0.0000001) {
                    precision = 9;
                    minMove = 0.000000001;
                }

                candleSeries.applyOptions({
                    priceFormat: {
                        type: 'price',
                        precision: precision,
                        minMove: minMove,
                    },
                });

                if (update) {
                    candleSeries.update(update);
                    volumeSeries.update(update);
                    // const fullData = candleSeries.data();

                    // for (const container of smaLineContainer) {
                    //     try {
                    //         chart.removeSeries(container);
                    //     } catch (e) {
                    //     }
                    // }
                    //
                    // smaLineContainer = [];
                    //
                    // smaLineContainer.push(createSMALineSeries(fullData, 20, 'rgba(255, 0, 0, 1)'));
                    // smaLineContainer.push(createSMALineSeries(fullData, 50, 'rgb(130,217,118)'));
                    // smaLineContainer.push(createSMALineSeries(fullData, 100, 'rgb(97,122,248)'));
                    // smaLineContainer.push(createSMALineSeries(fullData, 200, 'rgb(3,3,66)'));
                }
            } catch (e) {
                console.log(e);
            }
        };
    };

    // Функція конвертації однієї свічки
    const convertCandle = (candle) => {
        return {
            // time: moment(candle[0]).format('YYYY-MM-DD'),
            time: candle[0] / 1000,
            open: parseFloat(candle[1]),
            high: parseFloat(candle[2]),
            low: parseFloat(candle[3]),
            close: parseFloat(candle[4]),
            volume: parseFloat(candle[7]),
            value: parseFloat(candle[7]),
        };
    }

    const updateLegend = (param) => {
        // console.log('updateLegend')

        if (firstRow === null) {
            return;
        }

        let openFormatted = '';
        let closeFormatted = '';
        let highFormatted = '';
        let lowFormatted = '';
        let volumeFormatted = '';
        try {
            if (param.time) {
                const data = param.seriesData.get(candleSeries);
                const volume = param.seriesData.get(volumeSeries);
                closeFormatted = data.close.toFixed(6);
                openFormatted = data.open.toFixed(6);
                highFormatted = data.high.toFixed(6);
                lowFormatted = data.low.toFixed(6);
                volumeFormatted = formatNumber(Math.ceil(volume.value * 100) / 100);
                firstRow.innerHTML = `<strong>${chartSymbol}</strong> O: ${openFormatted} H: ${highFormatted} L: ${lowFormatted} C: ${closeFormatted} V: $ ${volumeFormatted}`;
            }
        } catch (e) {
            firstRow.innerHTML = '';
            console.log(e);
        }
    }

    // Функція конвертації масиву свічок
    const convertData = (data) => {
        // console.log('convertData')
        try {
            return data.map(candle => convertCandle(candle));
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    const addSeries = () => {
        // console.log('addSeries')

        if (candleSeries) {
            if (chart !== null) chart.removeSeries(candleSeries);
            candleSeries = null;
        }

        if (volumeSeries) {
            if (chart !== null) chart.removeSeries(volumeSeries);
            volumeSeries = null;
        }

        if (chart === null) {
            alert('chart === null');
            return;
        }

        candleSeries = chart.addCandlestickSeries({
            upColor: '#82d976',
            downColor: '#030342',
            borderDownColor: '#030342',
            borderUpColor: '#82d976',
            wickDownColor: '#030342',
            wickUpColor: '#030342',

        });

        candleSeries.priceScale().applyOptions({
            scaleMargins: {
                // positioning the price scale for the candle series
                top: 0.1,
                bottom: 0.3,
            }
        });

        candleSeries.applyOptions({
            priceFormat: {
                type: 'price',
                precision: 4,
                minMove: 0.0001,
            },
        });

        // candleSeries.setData([]);

        volumeSeries = chart.addHistogramSeries({
            color: '#8d9df1',
            priceFormat: {
                type: 'volume',
                precision: 2,
                minMove: 0.01,
            },
            priceScaleId: '', // set as an overlay by setting a blank priceScaleId
            // set the positioning of the volume series
            // scaleMargins: {
            //     top: 0.9, // highest point of the series will be 90% away from the top
            //     bottom: 0,
            // },
        });

        volumeSeries.priceScale().applyOptions({
            scaleMargins: {
                top: 0.8, // highest point of the series will be 70% away from the top
                bottom: 0,
            },
        });

        // volumeSeries.setData([]);

    }

    // Resize chart on container resizes
    handleResize = () => {
        window.requestAnimationFrame(() => {
            try {
                chart.applyOptions({width: chartContainerRef.current.clientWidth});
            } catch (e) {
                console.log(e);
            }
        });
    };

    // Create watermark
    const createWatermark = () => {
        chart.applyOptions({
            watermark: {
                visible: true,
                fontSize: 60,
                horzAlign: 'center',
                vertAlign: 'center',
                color: 'rgba(200,211,255,0.49)',
                text: chartSymbol + ', ' + chartInterval.toUpperCase(),
            },
        });
    }

    // Функція ініціалізації графіка
    const initializeChart = () => {
        // console.log('TVChartLight initializeChart')

        if (chartSymbol === '' || chartInterval === '') {
            return;
        }

        // Очищаємо графік
        if (chart !== null) {
            chart.removeSeries(candleSeries);
            chart.removeSeries(volumeSeries);
            candleSeries = null;
            volumeSeries = null;
        } else {
            chartContainerRef.current.innerHTML = '';
            chart = createChart(chartContainerRef.current, chartOptions);
            chart.subscribeCrosshairMove(updateLegend);
            window.addEventListener('resize', handleResize);
        }

        // Створюємо новий графік
        createWatermark();

        // Додаємо свічки і об'єм
        connectToWS();

        chart.timeScale().fitContent();


        // Первая строка легенды
        if (legendPrice !== null) {
            try {
                legendPrice.remove();
            } catch (e) {
            }
        }

        if (firstRow !== null) {
            try {
                firstRow.remove();
            } catch (e) {
            }
        }

        legendPrice = document.createElement('div');
        legendPrice.style = `position: absolute; left: 20px; top: 20px; z-index: 1; font-family: Monaco, Consolas, “Lucida Console”, monospace; font-size: 14px; line-height: 18px; font-weight: 600;`;
        chartContainerRef.current.appendChild(legendPrice);

        firstRow = document.createElement('div');
        firstRow.innerHTML = chartSymbol + ', ' + chartInterval.toUpperCase();
        firstRow.style.color = '#030342';
        legendPrice.appendChild(firstRow);
    }

    // const createSMALineSeries = (data, length, color) => {
    //     // console.log('createSMALineSeries', length)
    //     try {
    //         if (data.length < length) {
    //             return null;
    //         }
    //
    //         const smaData = calculateSMA(data, length);
    //         if (smaData.length > 0) {
    //             const smaLine = chart.addLineSeries({
    //                 color: color,
    //                 lineWidth: 1,
    //             });
    //             smaLine.setData(smaData);
    //
    //             return smaLine;
    //         }
    //
    //         return null;
    //     } catch (e) {
    //         return null;
    //     }
    // }

    useEffect(() => {
        // console.log('TVChartLight useEffect [chartSymbol, chartInterval]', chartSymbol, chartInterval)

        if (chartSymbol === '' || chartInterval === '') return;

        initializeChart();

    }, [chartSymbol, chartInterval]);

    return (
        <div style={{minHeight: '85vh', minWidth: '95wv'}}
             ref={chartContainerRef}
        />
    );
});

TVChartLight.propTypes = {
    symbol: PropTypes.string.isRequired,
    interval: PropTypes.string.isRequired,
    openOrderTime: PropTypes.string.isRequired,
    closeOrderTime: PropTypes.string.isRequired,
};