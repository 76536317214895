import React from 'react';
import {Column} from '@ant-design/plots';
import PropTypes from "prop-types";

const ProfitChartBar = ({data, date_field, height}) => {

    if (!data) return (<></>);

    const height_h = height || 213;

    const data_arr = []
    try {
        data.forEach((item) => {
            data_arr.push({
                [date_field]: item[date_field],
                PNL: item.PNL
            })
        });
    } catch (e) {
        console.log(e);
    }

    const data_t = data_arr.reverse();

    const paletteSemanticRed = '#F4664A';
    const brandColor = '#5B8FF9';
    const config = {
        data: data_t,
        xField: date_field,
        yField: 'PNL',
        height: height_h,
        color: (datum) => {
            const zeroDateEntry = data_t.find(item => item[date_field] === datum[date_field]);
            const zeroDatePNL = zeroDateEntry ? zeroDateEntry.PNL : null;
            return zeroDatePNL < 0 ? paletteSemanticRed : brandColor;
        },
        legend: false,
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: true,
                rotate: 6,
            },
        },
    };
    return <Column {...config} />;
};

ProfitChartBar.propTypes = {
    data: PropTypes.array.isRequired,
    date_field: PropTypes.string.isRequired,
    height: PropTypes.number,
};

export default ProfitChartBar;