import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {Button, Modal, Popconfirm, Table, Tag, Tooltip} from 'antd';
import TextArea from "antd/es/input/TextArea";
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    MinusOutlined,
    PauseOutlined,
    PlaySquareOutlined,
    SettingOutlined,
    StopOutlined
} from "@ant-design/icons";

import s from './StrategyTable.module.scss';
import {getCurrentPrice} from "../../Helpers/BinancelDataAPI";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import {formatNumber} from "../../Helpers/statistics_function";

export const StrategyTable = forwardRef(({loading, data, api, setStrategyStatus}, ref) => {

    const [showSettings, setShowSettings] = useState(false);
    const [settings, setSettings] = useState('');
    const [profit, setProfit] = useState([]);

    // Функція для відключення WebSocket викликається з батьківського компонента
    useImperativeHandle(ref, () => ({
        recalculateUnrealizedProfitHandler() {
            recalculateUnrealizedProfit();
        }
    }));

    if (!data) return (<></>);

    const pageSizeByWindowHeight = Math.round(window.innerHeight / 50) - 4;

    const paginationOptions = {
        pageSizeOptions: ['10', '20', '30', '50', '100', '500'], // кількість рядків, які можна вибрати
        showSizeChanger: true, // дозволяє користувачеві змінювати кількість рядків на сторінці
        defaultPageSize: pageSizeByWindowHeight, // кількість рядків на сторінці за замовчуванням
    };

    const getStatusText = (status) => {
        switch (status) {
            case 0:
                return 'На паузе';
            case 1:
                return 'Работают';
            case 2:
                return 'Ожидают закрытия';
            case 99:
                return 'Ручное закрытие';
            default:
                return status;
        }
    }

    const filterData = data => formatter => data.map(item => ({
        text: getStatusText(formatter(item)),
        value: formatter(item)
    }));

    const select_strategy_status = [
        {
            label: <strong>Стратегия работает</strong>,
            value: 1,
            icon: <PlaySquareOutlined/>,
            color: {color: '#52c41a'},
        },
        {
            label: <strong>Стратегия на паузе</strong>,
            value: 0,
            icon: <PauseOutlined/>,
            color: {color: '#030342'},
        },
        {
            label: <strong>Стратегия на паузе</strong>,
            value: 99,
            icon: <CloseOutlined/>,
            color: {color: '#b21a2d'},
        },
        {
            label: <strong>Стратегия на паузе</strong>,
            value: 2,
            icon: <StopOutlined/>,
            color: {color: '#617af8'},
        },
        {
            label: <strong>Стратегия будет удалена</strong>,
            value: 3,
            icon: <DeleteOutlined/>,
            color: {color: '#efae09'},
        },
    ];

    const select_strategy_actions = [
        {
            label: <strong>Запустить</strong>,
            value: 1,
            icon: <PlaySquareOutlined/>,
            color: {color: '#52c41a'},
        },
        {
            label: <strong>Приостановить</strong>,
            value: 0,
            icon: <PauseOutlined/>,
            color: {color: '#030342'},
        },
        {
            label: <><strong>Остановить</strong><br/>и дождаться закрытия сделок по стратегии</>,
            value: 2,
            icon: <StopOutlined/>,
            color: {color: '#617af8'},
        },
        {
            label: <><strong>Остановить</strong><br/>и закрыть сделки немедленно</>,
            value: 99,
            icon: <CloseOutlined/>,
            color: {color: '#b21a2d'},
        },
        // {
        //     label: 'Удалить из списка: сделки закрыть по стратегии',
        //     value: 3,
        //     icon: <DeleteOutlined/>,
        //     color: {color: '#efae09'},
        // },
    ];

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 20,
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Имя стратегии',
            dataIndex: 'strategy_name',
            key: 'strategy_name',
            align: 'center',
            width: 100,
            sorter: (a, b) => a.strategy_name.localeCompare(b.strategy_name),
        },
        {
            title: 'Поток',
            dataIndex: 'strategy_settings_name',
            key: 'strategy_settings_name',
            align: 'center',
            width: 100,
            sorter: (a, b) => a.strategy_settings_name.localeCompare(b.strategy_settings_name),
        },
        {
            title: 'Открытые сделки',
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            width: 100,
            // sorter: (a, b) => profit[a.id] - profit[b.id],
            render: (text, record) => {
                if (record.state === 3) {
                    let tagColor = 'green';
                    if (profit[record.id] < 0) {
                        tagColor = 'red';
                    }
                    return (
                        <>
                            <Tooltip title={<strong>Открыты сделки по потоку</strong>}>
                                <CheckOutlined style={{color: '#82d976', fontSize: '20px'}}/>
                            </Tooltip>
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip title='Нереализованный профит по открытых сделках'>
                                <Tag style={{fontSize: '15px'}} color={tagColor}>{profit[record.id]}</Tag>
                            </Tooltip>
                        </>
                    );
                } else {
                    return (
                        <Tooltip title={<strong>Открытых сделок нет</strong>}>
                            <MinusOutlined style={{color: '#030342', fontSize: '20px'}}/>
                        </Tooltip>
                    )
                }
            }
        },
        {
            title: 'Дата изменения',
            dataIndex: 'datetime_change',
            key: 'datetime_change',
            align: 'center',
            width: 100,
            sorter: (a, b) => moment(a.datetime_change).unix() - moment(b.datetime_change).unix()
        },
        {
            title: 'Статус',
            dataIndex: 'is_active',
            key: 'is_active',
            align: 'center',
            width: 80,
            filters: _.uniqWith(filterData(data)(i => i.is_active), _.isEqual),
            onFilter: (value, record) => record.is_active === value,
            filterSearch: true,
            render: (text, record) => {
                return (
                    <>
                        {select_strategy_status.map((item) => {
                            if (item.value === record.is_active) {
                                return (
                                    <Tooltip title={item.label} key={item.value}>
                                        <Button shape="round"
                                                disabled={true}
                                                ghost={true}
                                                icon={item.icon}
                                                size='middle'
                                                style={item.color}
                                        />
                                    </Tooltip>
                                )
                            }
                        })}
                    </>
                )
            }
        },
        {
            title: 'Изменить статус',
            dataIndex: 'is_active',
            key: 'is_active',
            align: 'center',
            width: 120,
            render: (text, record) => {
                return (
                    <>
                        {select_strategy_actions.map((item) => {
                            return (
                                <Tooltip title={item.label} key={item.value}>
                                    <Popconfirm
                                        title="Изменение статуса стратегии"
                                        description="Вы уверены, что хотите изменить статус стратегии?"
                                        onConfirm={() => handleSetStrategyStatus(record.id, item.value)}
                                        // onCancel={cancel}
                                        okText="Да"
                                        cancelText="Нет"
                                    >
                                        <Button shape="circle"
                                                ghost={true}
                                                icon={item.icon}
                                                size='middle'
                                                style={item.color}
                                            // onClick={() => handleSetStrategyStatus(record.id, item.value)}
                                        />
                                    </Popconfirm>

                                </Tooltip>
                            )
                        })}
                    </>
                )
            }
        },
        {
            title: '',
            dataIndex: 'is_active',
            key: 'is_active',
            align: 'center',
            width: 50,
            render: (text, record) => {
                return (
                    <Tooltip title={<strong>Настройки входа в сделку</strong>}>
                        <Button shape="circle"
                                icon={<SettingOutlined/>}
                                onClick={() => handleShowSettingsWindowClick(record.settings)}
                                size='middle'/>
                    </Tooltip>
                )
            }
        },
    ];

    function handleShowSettingsWindowClick(entry_strategy_setting) {
        setSettings(entry_strategy_setting);
        setShowSettings(true);
    }

    function handleSetStrategyStatus(strategy_settings_id, status) {
        setStrategyStatus(strategy_settings_id, status);
    }


    const calculateProfit = async (symbol, entry_price, amount, direction) => {
        try {
            const exit_price = await getCurrentPrice(symbol);
            let profit = 0;

            if (direction.toLowerCase() === 'buy') {
                profit = (exit_price - entry_price) * amount;
            } else if (direction.toLowerCase() === 'sell') {
                profit = (entry_price - exit_price) * amount;
            }

            profit = Math.ceil(profit * 100) / 100;
            return profit;

        } catch (e) {
            console.log(e);
        }
    }

    function get_strategy_orders(strategy_settings_id) {

        // Get Login Info
        api.get_strategy_orders(strategy_settings_id).then(async (result) => {

            if (result.result) {
                let profitAll = 0.
                const profits = result.data.map((item) =>
                    calculateProfit(item.symbol, item.entry_price, item.amount, item.side)
                );
                const resolvedProfits = await Promise.all(profits);
                resolvedProfits.forEach((profit) => {
                    profitAll += profit;
                });

                profitAll = Math.ceil(profitAll * 100) / 100;
                setProfit(prevProfit => ({
                    ...prevProfit,
                    [strategy_settings_id]: profitAll
                }));

                // setProfit({...profit, [strategy_settings_id]: profitAll});
                // alert('Нереализованный профит: ' + profitAll);
            } else {
                alert(result.error);
            }

        }).catch((error) => {
            alert(error.message);
        });
    }

    const recalculateUnrealizedProfit = () => {
        data.forEach((item) => {
            if (item.state === 3) {
                get_strategy_orders(item.id);
            }
        });
    }

    return (
        <>
            <Modal
                title="Настройки стратегии"
                centered
                open={showSettings}
                onOk={() => setShowSettings(false)}
                onCancel={() => setShowSettings(false)}
                width={800}
            >
                {(settings && settings !== '') &&
                    <TextArea
                        rows={20}
                        style={{fontWeight: 400, fontSize: 16}}
                        value={JSON.stringify(JSON.parse(settings), undefined, 4)}
                    />
                }

            </Modal>

            <Table
                // title={() => <h3>{title}</h3>}
                className={s.tableClass}
                loading={loading}
                size="small"
                // sticky={true}
                dataSource={data}
                columns={columns}
                bordered={true}
                pagination={paginationOptions}
                style={{fontWeight: 500}}
                summary={() => {
                    let totalStrategies = 0;
                    let unrealizePNLSum = 0

                    data.forEach(({id}) => {
                        totalStrategies++;
                        unrealizePNLSum += profit[id] !== undefined ? profit[id] : 0;
                    });

                    unrealizePNLSum = formatNumber(Math.round(unrealizePNLSum * 100) / 100);

                    return (
                        <Table.Summary.Row style={{background: '#ececec'}}>
                            <Table.Summary.Cell index={1}
                                                align={'center'}><strong>Всего: {totalStrategies}</strong></Table.Summary.Cell>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'}>
                                <Tooltip title="Сумма">
                                    <strong>Σ: {unrealizePNLSum}</strong>
                                </Tooltip>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
                // onRow={(record) => {
                // return {
                //         // onClick: () => onChooseSignal(record.ID), // click row
                // onDoubleClick: () => get_strategy_orders(record.id), // double click row
                // // onContextMenu: event => {
                //         // }, // right button click row
                //         // onMouseEnter: event => {
                //         // }, // mouse enter row
                //         // onMouseLeave: event => {
                //         // }, // mouse leave row
                // };
                //>}}
            />
        </>);

});

StrategyTable.propTypes = {
    api: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    setStrategyStatus: PropTypes.func.isRequired,
};