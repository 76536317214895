import React, {Component} from 'react';
import {Spin} from "antd";
import s from "./SpinLoader.module.scss";

class SpinLoader extends Component {
    render() {
        return (
            <div className={s.loader_wrap}>
                <Spin size="large"/>
            </div>
        );
    }
}

export default SpinLoader;