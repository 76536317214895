const INITIAL_STATE = {
    uid: 0,
    token: '',
    isLogin: false,
    login: false,
    active: false,
    loading: false,
    showLoader: true
};

export default INITIAL_STATE;
