import React from 'react';
import {Table, Tooltip} from 'antd';

import s from './ProfitTable.module.css';
import PropTypes from "prop-types";
import moment from "moment/moment";

function ProfitTable({data, loading, title, date_field}) {

    if (!data) return (<></>);

    const paginationOptions = {
        pageSizeOptions: ['3', '5', '7', '10', '15', '30', '50', '100', '500'], // кількість рядків, які можна вибрати
        showSizeChanger: true, // дозволяє користувачеві змінювати кількість рядків на сторінці
        defaultPageSize: 20, // кількість рядків на сторінці за замовчуванням
    };

    const columns = [
        {
            title: 'Дата',
            dataIndex: date_field,
            key: date_field,
            align: 'center',
            width: 200,
            sorter: (a, b) => moment(a[date_field]).unix() - moment(b[date_field]).unix()
            // render: date => new Date(date).toISOString().split('T')[0],
        },
        {
            title: '💰❌ сделки',
            dataIndex: 'profitTrades',
            key: 'profitTrades',
            align: 'center',
            render: (text, record) => {
                return <Tooltip title="Закрытые сделки с прибылью">{text}</Tooltip>
            },
            sorter: (a, b) => a.profitTrades - b.profitTrades
            // width: 100,
        },
        {
            title: '🤬❌ сделки',
            dataIndex: 'lossTrades',
            key: 'lossTrades',
            align: 'center',
            render: (text, record) => {
                return <Tooltip title="Закрытые сделки в убыток">{text}</Tooltip>
            },
            sorter: (a, b) => a.profitTrades - b.profitTrades

            // width: 200,
        },
        {
            title: '⌛️ сделки',
            dataIndex: 'openedTrades',
            key: 'openedTrades',
            align: 'center',
            // width: 200,
            render: (text, record) => {
                if (record.openedTrades > 0) {
                    return <Tooltip title="Открытые сделки"><span
                        style={{background: 'lightblue'}}>{record.openedTrades}</span></Tooltip>
                } else {
                    return <Tooltip title="Открытые сделки"><span>{record.openedTrades}</span></Tooltip>
                }
            },
            sorter: (a, b) => a.openedTrades - b.openedTrades

        },
        {
            title: 'Win.Ratio',
            dataIndex: 'winRatio',
            key: 'winRatio',
            align: 'center',
            // width: 200,
            render: (text, record) => {
                const finRez = Math.round(record.winRatio);
                if (finRez > 50) {
                    return <span style={{background: '#c0fabb'}}>{finRez}%</span>
                } else if (finRez < 50) {
                    return <span style={{background: '#fce4e4'}}>{finRez}%</span>
                } else {
                    return <span>{finRez}</span>
                }
            },
            sorter: (a, b) => a.winRatio - b.winRatio

        },
        {
            title: 'Профит на сделку, $',
            dataIndex: 'avgPerTrade_PNL',
            key: 'avgPerTrade_PNL',
            align: 'center',
            // align: 'right',
            width: 200,
            render: (text, record) => {
                const finRez = Math.round(record.avgPerTrade_PNL * 100) / 100;
                if (finRez > 0) {
                    return <span style={{background: '#c0fabb'}}>{finRez}</span>
                } else if (finRez < 0) {
                    return <span style={{background: '#fce4e4'}}>{finRez}</span>
                } else {
                    return <span>{finRez}</span>
                }
            },
            sorter: (a, b) => a.avgPerTrade_PNL - b.avgPerTrade_PNL

        },
        {
            title: 'Дневной профит, $',
            dataIndex: 'PNL',
            key: 'PNL',
            align: 'center',
            // width: 200,
            render: (text, record) => {
                const finRez = Math.round(record.PNL * 100) / 100;
                if (finRez > 0) {
                    return <span style={{background: '#c0fabb'}}>{finRez}</span>
                } else if (finRez < 0) {
                    return <span style={{background: '#fce4e4'}}>{finRez}</span>
                } else {
                    return <span>{finRez}</span>
                }
            },
            sorter: (a, b) => a.PNL - b.PNL

        },
    ];


    return (
        <Table
            title={() => <h3>{title}</h3>}
            className={s.tableClass}
            loading={loading}
            size="small"
            // sticky={true}
            dataSource={data}
            columns={columns}
            bordered={true}
            pagination={paginationOptions}
            // style={{fontWeight: 500}}
            summary={data => {
                let totalProfit = 0;

                data.forEach(({PNL}) => {
                    totalProfit += PNL;
                });

                totalProfit = Math.round(totalProfit * 100) / 100;

                return (
                    <Table.Summary.Row style={{background: '#ececec'}}>
                        <Table.Summary.Cell index={0}><strong>Итого за период</strong></Table.Summary.Cell>
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                        <Table.Summary.Cell index={1}
                                            align={'right'}><strong>{totalProfit}</strong></Table.Summary.Cell>
                    </Table.Summary.Row>
                );
            }}
        />);
}

ProfitTable.propTypes = {
    data: PropTypes.array.isRequired,
    date_field: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
};

export default ProfitTable;
