import React, {memo, PureComponent} from "react";

import {notification} from 'antd';
import PropTypes from "prop-types";

class Error extends PureComponent {

    render() {
        const {message} = this.props;

        if (message && message.length > 0) {
            notification.error({
                message: 'Error',
                description: message,
                duration: 7,
                style: {
                    width: 350,
                }
            });
        }

        return (<></>);
    }
}

Error.propTypes = {
    message: PropTypes.string.isRequired,
};

export default memo(Error);