import React from 'react';
import {Table} from 'antd';

import s from './TopSymbolTable.module.css';
import PropTypes from "prop-types";

function TopSymbolTable({data, loading, title}) {

    if (!data) return (<></>);

    const paginationOptions = {
        pageSizeOptions: ['3', '5', '7', '10', '15', '30', '50', '100', '500'], // кількість рядків, які можна вибрати
        showSizeChanger: true, // дозволяє користувачеві змінювати кількість рядків на сторінці
        defaultPageSize: 20, // кількість рядків на сторінці за замовчуванням
    };

    const filterData = data => formatter => data.map(item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const columns = [
        {
            title: 'Актив',
            dataIndex: 'symbol',
            key: 'symbol',
            align: 'center',
            // width: 50,
            sorter: (a, b) => a.symbol.localeCompare(b.symbol),
            // filterMode: 'tree',
            filters: filterData(data)(i => i.symbol),
            onFilter: (value, record) => record.symbol.includes(value),
            filterSearch: true,
        },
        {
            title: 'Фин. результат, $',
            dataIndex: 'pnl',
            key: 'pnl',
            align: 'center',
            sorter: (a, b) => a.pnl - b.pnl,
            render: (text, record) => {
                const finRez = Math.round(record.pnl * 100) / 100;
                if (finRez > 0) {
                    return <span style={{background: '#c0fabb'}}>{finRez}</span>
                } else {
                    return <span style={{background: '#fce4e4'}}>{finRez}</span>
                }
            },
        },
        {
            title: 'Прибыль, $',
            dataIndex: 'profit',
            key: 'profit',
            align: 'center',
            sorter: (a, b) => a.profit - b.profit
            // width: 200,
        },
        {
            title: 'Убыток, $',
            dataIndex: 'loss',
            key: 'loss',
            align: 'center',
            sorter: (a, b) => a.loss - b.loss
            // width: 200,
        },
        {
            title: 'Прибыльных сделок',
            dataIndex: 'profit_cnt',
            key: 'profit_cnt',
            align: 'center',
            sorter: (a, b) => a.profit_cnt - b.profit_cnt,
            // align: 'right',
            // width: 40,
        },
        {
            title: 'Убыточных сделок',
            dataIndex: 'loss_cnt',
            key: 'loss_cnt',
            align: 'center',
            sorter: (a, b) => a.loss_cnt - b.loss_cnt,
            // align: 'right',
            // width: 40,
        },
        {
            title: 'Всего сделок',
            dataIndex: 'cnt',
            key: 'cnt',
            align: 'center',
            sorter: (a, b) => a.cnt - b.cnt,
            // align: 'right',
            // width: 40,
        },
    ];


    return (
        <Table
            title={() => <h3>{title}</h3>}
            className={s.tableClass}
            loading={loading}
            size="small"
            // sticky={true}
            dataSource={data}
            columns={columns}
            bordered={true}
            pagination={paginationOptions}
            // style={{fontWeight: 500}}
            // summary={data => {
            //     let totalProfit = 0;
            //
            //     data.forEach(({PNL}) => {
            //         totalProfit += PNL;
            //     });
            //
            //     totalProfit = Math.round(totalProfit * 100) / 100;
            //
            //     return (
            //         <Table.Summary.Row style={{background: '#ececec'}}>
            //             <Table.Summary.Cell index={0}><strong>Итого за период</strong></Table.Summary.Cell>
            //             <Table.Summary.Cell index={0}></Table.Summary.Cell>
            //             <Table.Summary.Cell index={0}></Table.Summary.Cell>
            //             <Table.Summary.Cell index={0}></Table.Summary.Cell>
            //             <Table.Summary.Cell index={0}></Table.Summary.Cell>
            //             <Table.Summary.Cell index={0}></Table.Summary.Cell>
            //             <Table.Summary.Cell index={1}
            //                                 align={'right'}><strong>{totalProfit}</strong></Table.Summary.Cell>
            //         </Table.Summary.Row>
            //     );
            // }}
        />);
}

TopSymbolTable.propTypes = {
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
};

export default TopSymbolTable;
