import React, {memo, useEffect, useLayoutEffect, useState} from 'react';
import {Button, Col, DatePicker, Input, Row, Select, Space, Tooltip} from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import 'dayjs/locale/ru';

import {mobXStore} from "../../store";
import OrdersTable from "../OrdersTable";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import {getCurrentPrice} from "../../Helpers/BinancelDataAPI";
import PropTypes from "prop-types";

const {RangePicker} = DatePicker;

dayjs.extend(updateLocale)
dayjs.updateLocale('ru', {
    weekStart: 1
})

const sort_order_type = [
    {
        label: 'По дате Входа',
        value: 'entry_time',
    },
    {
        label: 'По дате Выхода',
        value: 'exit_time',
    }
]

const status_order_type = [
    {
        label: 'Все сделки',
        value: 'all',
    },
    {
        label: 'Открытые сделки',
        value: 'opened',
    },
    {
        label: 'Закрытые сделки',
        value: 'closed',
    }
]


const select_strategy_options = [
    {
        label: 'Все стратегии',
        value: 0,
    },
    {
        label: 'Pair Trading TF 1h',
        value: 1,
    },
    {
        label: 'Pair Trading TF 1D',
        value: 4,
    },
    {
        label: 'LinearRegression TF 1h',
        value: 2,
    },
    {
        label: 'VidkatShort TF 5m',
        value: 6,
    },
    {
        label: 'VidkatOne SHORT TF 5m',
        value: 7
    },
    {
        label: 'VidkatOne LONG TF 5m',
        value: 8
    },
    {
        label: 'VidkatOne SHORT TF 15m',
        value: 9
    },
    {
        label: 'VidkatOne LONG TF 15m',
        value: 10
    }
    // {
    //     label: 'StrategyRSI Test TF 1h',
    //     value: 3,
    // },
    // {
    //     label: 'DCA TF 1h',
    //     value: 5,
    // }
]

const dateRangePeriods = [
    {
        label: 'Сегодня',
        value: [dayjs(), dayjs()],
    },
    {
        label: 'Вчера',
        value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')],
    },
    {
        label: 'Эта неделя',
        value: [dayjs().startOf('week').add(1, 'd'), dayjs().endOf('week').add(1, 'd')],
    },
    {
        label: 'Прошлая неделя',
        value: [dayjs().add(-1, 'week').startOf('week').add(1, 'd'), dayjs().add(-1, 'week').endOf('week').add(1, 'd')],
    },
    {
        label: 'Этот месяц',
        value: [dayjs().startOf('month'), dayjs().endOf('month')],
    },
    {
        label: 'Прошлый месяц',
        value: [dayjs().add(-1, 'month').startOf('month'), dayjs().add(-1, 'month').endOf('month')],
    },
    {
        label: 'Последние 7 дней',
        value: [dayjs().add(-6, 'd'), dayjs()],
    },
    {
        label: 'Последние 10 дней',
        value: [dayjs().add(-9, 'd'), dayjs()],
    },
    {
        label: 'Последние 15 дней',
        value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
        label: 'Последние 30 дней',
        value: [dayjs().add(-29, 'd'), dayjs()],
    },
    {
        label: 'Последние 90 дней',
        value: [dayjs().add(-89, 'd'), dayjs()],
    },
    {
        label: 'Последние 180 дней',
        value: [dayjs().add(-179, 'd'), dayjs()],
    },
    {
        label: 'Последние 365 дней',
        value: [dayjs().add(-364, 'd'), dayjs()],
    }
];

function OrdersBlock({setErrorMsg, api}) {

    // const windowHeight = window.innerHeight
    const [loading, setLoading] = useState(false);
    const [errTimeout, setErrTimeout] = useState(0); // таймаут для вывода ошибки

    const [startReportDate, setStartReportDate] = useState(dayjs().add(-6, 'd'));
    const [endReportDate, setEndReportDate] = useState(dayjs());

    const [strategySelectValue, setStrategySelectValue] = useState(0);

    const [ordersData, setOrdersData] = useState([]);

    const [filterSymbol, setFilterSymbol] = useState('');

    const [sorterField, setSorterField] = useState('entry_time');

    const [statusFilter, setStatusFilter] = useState('opened');

    const [lastErrMsg, setLastErrMsg] = useState('');

    useEffect(() => {

        // // Обновляем данные каждую минуту
        // const timerRefresh = setTimeout(() => {
        //     get_orders_journal()
        // }, 60000);

        return () => {
            setErrorMsg('');
            if (errTimeout !== null) {
                clearTimeout(errTimeout);
                // clearTimeout(timerRefresh);
            }
        }
    }, []);

    useLayoutEffect(() => {
        get_orders_journal()
    }, [startReportDate, endReportDate, strategySelectValue, filterSymbol, sorterField, statusFilter]);

    useLayoutEffect(() => {

    }, [ordersData]);


    // Выводим ошибку
    function handlerSetErrorMsg(error) {
        if (error !== '' && error !== lastErrMsg) {
            setLastErrMsg(error);
            setErrorMsg(error);

            setErrTimeout(setTimeout(() => {
                setErrorMsg('');
            }, 5000));
        }
    }


    const calculateProfit = async (symbol, entry_price, amount, direction) => {
        try {
            const exit_price = await getCurrentPrice(symbol);
            let profit = 0;

            if (direction.toLowerCase() === 'buy') {
                profit = (exit_price - entry_price) * amount;
            } else if (direction.toLowerCase() === 'sell') {
                profit = (entry_price - exit_price) * amount;
            }

            profit = Math.ceil(profit * 100) / 100;
            return profit;

        } catch (e) {
            console.log(e);
        }
    }

    const calculateProfitData = async (data) => {
        setLoading(true);
        const dataWithUnrealizedPNL = await Promise.all(
            data.map(async (item) => {
                if (item.exit_time !== '0000-00-00' && item.exit_time !== '1970-01-01') {
                    return {...item, unrealizePNL: 0};
                }

                const unrealizePNL = await calculateProfit(item.symbol, item.entry_price, item.amount, item.side);
                return {...item, unrealizePNL};
            })
        );

        setOrdersData(dataWithUnrealizedPNL);
        setLoading(false);

        // Тепер dataWithUnrealizedPNL містить ваші дані з доданим полем unrealizePNL
        // Якщо ви хочете оновити props.data, вам, можливо, доведеться використовувати стан або інші засоби, в залежності від того, як ви керуєте даними в вашому додатку.
    }


    // статистика PnL по времени выхода из позиции
    function get_orders_journal() {

        // Get Login Info
        setLoading(true);
        setErrorMsg('');

        api.get_orders_journal(strategySelectValue, startReportDate, endReportDate, filterSymbol, sorterField, statusFilter).then((result) => {

            if (result.result) {
                // setOrdersData(result.data);
                (async () => await calculateProfitData(result.data) && setLoading(false))();
            } else {
                setLoading(false);
                handlerSetErrorMsg(result.error);

                if (result.action === 'logout') {
                    // setErrorMsg('Сессия закончилась');
                    setTimeout(() => {
                        mobXStore.setKey('isLogin', false);
                    }, 1000);
                }
            }

        }).catch((error) => {
            setLoading(false);
            handlerSetErrorMsg(error.message);
        });
    }

    return (
        <>
            <Row gutter={24} align={"middle"} justify={'center'} style={{background: '#efefef'}}>
                <Col>
                    <Space size={"large"} wrap={true} align={'center'}
                           style={{width: '100%', justifyContent: 'center'}}>

                        <Select
                            size={'large'}
                            defaultValue={strategySelectValue}
                            onChange={(value) => {
                                if (value !== null) {
                                    setStrategySelectValue(value);
                                }
                            }}
                            style={{
                                width: 250,
                            }}
                            options={select_strategy_options}
                        />

                        <Select
                            size={'large'}
                            defaultValue={sorterField}
                            onChange={(value) => {
                                if (value !== null) {
                                    setSorterField(value);
                                }
                            }}
                            style={{
                                width: 200,
                            }}
                            options={sort_order_type}
                        />

                        <Select
                            size={'large'}
                            defaultValue={statusFilter}
                            onChange={(value) => {
                                if (value !== null) {
                                    setStatusFilter(value);
                                }
                            }}
                            style={{
                                width: 200,
                            }}
                            options={status_order_type}
                        />

                        <RangePicker
                            size={'large'}
                            locale={locale}
                            // disabled={!showDataRange}
                            // style={{marginBottom: '12px'}}
                            presets={dateRangePeriods}
                            onChange={(dates) => {
                                if (dates !== null) {
                                    setStartReportDate(dates[0].format('YYYY-MM-DD'));
                                    setEndReportDate(dates[1].format('YYYY-MM-DD'));
                                }
                            }}
                            style={{
                                width: 300,
                            }}
                            value={[dayjs(startReportDate, 'YYYY-MM-DD'), dayjs(endReportDate, 'YYYY-MM-DD')]}
                            defaultValue={[dayjs().add(-6, 'd'), dayjs()]}
                        />
                        <Input
                            size="large"
                            placeholder="поиск по Тикеру"
                            prefix={<SearchOutlined/>}
                            onChange={(e) => {
                                setFilterSymbol(e.target.value.toUpperCase());
                            }}
                            style={{
                                width: 300,
                            }}
                            value={filterSymbol}
                        />

                        <Tooltip title="Обновить данные">
                            <Button
                                size={'large'}
                                onClick={() => {
                                    get_orders_journal()
                                }}
                                icon={<ReloadOutlined/>}
                                style={{color: '#617af8'}}
                            />
                        </Tooltip>
                    </Space>
                </Col>
            </Row>
            <Row gutter={24} align={'middle'} justify={'center'}>
                <Col span={24}>
                    <OrdersTable
                        reloadDataHandler={get_orders_journal}
                        api={api}
                        loading={loading}
                        data={ordersData}/>
                </Col>
            </Row>
        </>
    )
}

OrdersBlock.propTypes = {
    api: PropTypes.object.isRequired,
    setErrorMsg: PropTypes.func.isRequired,
};

export default memo(OrdersBlock);