import React, {useEffect, useState} from 'react';
import {
    Routes,
    Route
} from "react-router-dom";

import {mobXStore, observer} from "./store";

import SpinLoader from './components/SpinLoader';

import LoginPage from './pages/Login';
import DashboardPage from "./pages/Dashboard/DashboardPage";
import API from "./api";

function App(props) {

    const [auth, setAuth] = useState(mobXStore.getKey('isLogin'));

    useEffect(() => {
        mobXStore.showLoader(300);
    }, []);

    function handlerSetAuth() {
        mobXStore.showLoader(1000)
        console.log('handlerSetAuth', mobXStore.getKey('isLogin'));
        setAuth(mobXStore.getKey('isLogin'));
    }


    useEffect(() => {
        handlerSetAuth();
    }, [mobXStore.store.get('isLogin')])

    useEffect(() => {
        console.log('auth', auth);
    }, [auth])

    useEffect(() => {
        // handlerSetAuth();
    }, [mobXStore.store.get('showLoader')])

    if (mobXStore.getKey('showLoader')) {
        return (
            <SpinLoader/>
        );
    }

    return (
        <div>
            {!auth && <LoginPage api={API}/>}
            {auth &&
                <Routes>
                    <Route exact path="/" element={<DashboardPage api={API}/>}/>
                    <Route path="*" element={<LoginPage api={API}/>}/>
                </Routes>
            }
        </div>
    );
}

export default observer(App);