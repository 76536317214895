import React, {useEffect, useRef, useState} from 'react';
import {Button, Modal, Table, Tag, Tooltip} from 'antd';
import TextArea from "antd/es/input/TextArea";
import {EditOutlined, SettingOutlined} from "@ant-design/icons";

import _ from "lodash";

import s from './OrdersTable.module.scss';
import TVChartLight from "../TVChartLight";
import {formatNumber} from "../../Helpers/statistics_function";
import PropTypes from "prop-types";

function OrdersTable({loading, data, api, reloadDataHandler}) {

    const commentTextAreaRef = useRef();

    const chartRef = useRef();

    const [showSettings, setShowSettings] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const [settings, setSettings] = useState('');
    const [commentsId, setCommentsId] = useState(0);
    const [comments, setComments] = useState('');

    const [showChart, setShowChart] = useState(false);
    const [chartSymbol, setChartSymbol] = useState('');
    const [chartInterval, setChartInterval] = useState('5m');
    const [openOrderTime, setOpenOrderTime] = useState('');
    const [closeOrderTime, setCloseOrderTime] = useState('');

    useEffect(() => {
    }, [comments]);

    if (!data) return (<></>);

    const pageSizeByWindowHeight = Math.round(window.innerHeight / 50) - 4;

    const paginationOptions = {
        pageSizeOptions: ['10', '20', '30', '50', '100', '500'], // кількість рядків, які можна вибрати
        showSizeChanger: true, // дозволяє користувачеві змінювати кількість рядків на сторінці
        defaultPageSize: pageSizeByWindowHeight, // кількість рядків на сторінці за замовчуванням
    };

    const filterData = data => formatter => data
        .map(item => formatter(item))
        .filter(formattedItem => formattedItem)  // Видалення пустих записів
        .map(formattedItem => ({
            text: formattedItem,
            value: formattedItem
        }));

    const columns = [
            {
                title: '',
                dataIndex: 'side',
                key: 'side',
                align: 'center',
                filters: _.uniqWith(filterData(data)(i => i.side), _.isEqual),
                onFilter: (value, record) => record.side === value,
                filterSearch: true,
                render: (text, record) => {
                    if (record.side.toUpperCase() === 'BUY') {
                        return <span style={{color: 'green'}}> ▲ </span>
                    } else {
                        return <span style={{color: 'red'}}> ▼</span>
                    }
                },
                // align: 'right',
            },
            {
                title: 'Дата входа',
                dataIndex: 'entry_time',
                key: 'entry_time',
                align: 'center',
                width: 160,
                filters: _.uniqWith(filterData(data)(i => i.entry_time), _.isEqual),
                onFilter: (value, record) => record.entry_time === value,
                filterSearch: true,
                render: (text, record) => (
                    <Tooltip title={record.entry_time + ' ' + record.entry_time_hour}>
                        {record.entry_time}
                    </Tooltip>
                )
            },
            {
                title: 'Дата выхода',
                dataIndex: 'exit_time',
                key: 'exit_time',
                align: 'center',
                width: 160,
                filters: _.uniqWith(filterData(data)(i => i.exit_time), _.isEqual),
                onFilter: (value, record) => record.exit_time === value,
                filterSearch: true,
                render: (text, record) => {
                    if (record.exit_time === '0000-00-00' || record.exit_time === '1970-01-01') {
                        return <span>-</span>
                    } else {
                        return (
                            <Tooltip title={record.exit_time + ' ' + record.exit_time_hour}>
                                {record.exit_time}
                            </Tooltip>
                        )
                    }
                },
            },
            {
                title: 'Тикер',
                dataIndex: 'symbol',
                key: 'symbol',
                align: 'center',
                render: (text, record) => {
                    const symbol = record.symbol.toUpperCase().replace(':USDT', '').replace('/', '');
                    const orderEntryTime = record.entry_time + ' ' + record.entry_time_hour + ':00';
                    const orderExitTime = record.exit_time + ' ' + record.exit_time_hour + ':00';

                    return (
                        <Tooltip title="Посмотреть график цены на TradingView">
                            {/*<a href={`https://www.tradingview.com/chart/?symbol=${symbol}`} target="_blank"*/}
                            {/*   rel="noopener noreferrer">{symbol}</a>*/}
                            <Button
                                size={'small'}
                                type="link"
                                onClick={() => {
                                    showChartHandler({symbol}, orderEntryTime, orderExitTime)
                                }}>
                                {symbol}
                            </Button>
                        </Tooltip>
                    )
                },
                width: 100,
                sorter: (a, b) => a.symbol.localeCompare(b.symbol)
            },
            // {
            //     title: 'Order ID',
            //     dataIndex: 'order_id',
            //     key: 'order_id',
            //     align: 'center',
            //     // width: 100,
            // },
            {
                title: 'Order ID',
                dataIndex: 'client_order_id',
                key: 'client_order_id',
                align: 'center',
                sorter: (a, b) => a.client_order_id.localeCompare(b.client_order_id)
                // width: 200,
            },
            // {
            //     title: 'Position Side',
            //     dataIndex: 'position_side',
            //     key: 'position_side',
            //     align: 'center',
            //     // width: 200,
            // },
            {
                title: 'Цена входа, $',
                dataIndex: 'entry_price',
                key: 'entry_price',
                align: 'center',
                width: 100,
            },
            {
                title: 'Цена выхода, $',
                dataIndex: 'exit_price',
                key: 'exit_price',
                align: 'center',
                width: 100,
                render: (text, record) => {
                    if (record.exit_time === '0000-00-00' || record.exit_time === '1970-01-01') {
                        return <span>-</span>
                    } else {
                        return <span>{record.exit_price}</span>
                    }
                }
            },
            {
                title: 'Объем, контрактов',
                dataIndex: 'amount',
                key: 'amount',
                align: 'center',
            },
            {
                title: 'Объем, $',
                dataIndex: 'entry_cost',
                key: 'entry_cost',
                align: 'center',
                width: 130,
            },
            {
                title: 'Длительность, часов',
                dataIndex: 'duration_in_hours',
                key: 'duration_in_hours',
                align: 'center',
                sorter: (a, b) => a.duration_in_hours - b.duration_in_hours,
                render: (text, record) => {
                    if (record.exit_time === '0000-00-00' || record.exit_time === '1970-01-01') {
                        return <span>-</span>
                    } else {
                        return <span>{record.duration_in_hours}</span>
                    }
                },
                width: 80,
            },
            {
                title: 'Профит, $',
                dataIndex: 'pnl',
                key: 'pnl',
                align: 'right',
                sorter: (a, b) => (a.pnl - b.pnl) || (a.unrealizePNL - b.unrealizePNL),
                render: (text, record) => {
                    let finRez = Math.round(record.pnl * 100) / 100;
                    const unrealizePNL = Math.round(record.unrealizePNL * 100) / 100;

                    let finRezPerc = Math.round(finRez / record.entry_cost * 10000) / 100;
                    let cell_content = <div style={{fontSize: '12px', fontWeight: 500}}>
                        {finRez} <Tag>{finRezPerc}%</Tag>
                    </div>

                    if (finRez === 0) {
                        finRez = unrealizePNL;
                        finRezPerc = Math.round(finRez / record.entry_cost * 10000) / 100;
                        cell_content = (
                            <span style={{fontSize: '12px', fontWeight: 500}}>
                                <Tooltip title="Нереализованная прибыль по текущей цене">
                                    💰️ {finRez} <Tag>{finRezPerc}%</Tag>
                                </Tooltip>
                            </span>
                        )
                    }

                    return {
                        props: {
                            style: {background: finRez < 0 ? "#fce4e4" : "#d0efca"}
                        },
                        children: cell_content
                    }
                },
                width: 500,
            },
            {
                title: 'Причина закрытия',
                dataIndex: 'close_reason',
                key: 'close_reason',
                align: 'center',
                width: 220,
                sorter: (a, b) => a.close_reason.localeCompare(b.close_reason),
                filters: _.uniqWith(filterData(data)(i => i.close_reason), _.isEqual),
                onFilter: (value, record) => record.close_reason.includes(value),
                filterSearch: true,

            },
            {
                title: 'Комментарий',
                dataIndex: 'comment',
                key: 'comment',
                align: 'center',
                width: 200,
                sorter: (a, b) => a.comment.localeCompare(b.comment),
                filters: _.uniqWith(filterData(data)(i => i.comment), _.isEqual),
                onFilter: (value, record) => record.comment.includes(value),
                filterSearch: true,
            },
            {
                title: '',
                dataIndex: 'comment',
                key: 'comment',
                align: 'center',
                // onCell: () => {
                //     return {
                //         style: {
                //             background: '#eeeeee',
                //         }
                //     }
                // },
                width: 250,
                render: (text, record) => {
                    return (
                        <>
                            <Tooltip title="Отредактировать комментарий к сделке">
                                <Button shape="circle"
                                        align={'right'}
                                        icon={<EditOutlined/>}
                                        onClick={() => handleShowEditComment(record.id, text)}
                                        size='small'/>
                            </Tooltip>
                            <Tooltip title="Настройки входа в сделку">
                                <Button shape="circle"
                                        icon={<SettingOutlined/>}
                                        onClick={() => handleShowSettingsWindowClick(record.entry_strategy_setting)}
                                        size='small'/>
                            </Tooltip>
                        </>
                    )
                }
            },
        ]
    ;

    // статистика PnL по времени выхода из позиции
    const writeNewComments = () => {
        try {
            const comment = commentTextAreaRef.current.resizableTextArea.textArea.textContent;

            // Get Login Info
            api.set_new_comment(commentsId, comment).then(() => {
                reloadDataHandler();
                setShowComments(false);
            });
        } catch (e) {
        }
    }

    function showChartHandler(symbol, _openOrderTime, _closeOrderTime) {
        setChartSymbol(symbol.symbol);
        setShowChart(true);
        setOpenOrderTime(_openOrderTime);
        setCloseOrderTime(_closeOrderTime);
    }

    function handleShowSettingsWindowClick(entry_strategy_setting) {
        setSettings(entry_strategy_setting);
        setShowSettings(true);
    }

    function handleShowEditComment(id, text) {
        if (!text) text = ' ';

        setCommentsId(id);
        setComments(text);
        setShowComments(true);
    }

    return (
        <>
            <Modal
                destroyOnClose={true}
                // title={"Chart for " + chartSymbol + " TF: " + chartInterval}
                centered
                open={showChart}
                onOk={() => {
                    chartRef.current.disconnect_ws();
                    setShowChart(false);
                    setChartSymbol('');
                    setChartInterval('5m');
                }}
                onCancel={() => {
                    chartRef.current.disconnect_ws();
                    setShowChart(false);
                    setChartSymbol('');
                    setChartInterval('5m');
                }}
                style={{minWidth: '95vw'}}
            >
                <TVChartLight
                    ref={chartRef}
                    symbol={chartSymbol}
                    interval={chartInterval}
                    openOrderTime={openOrderTime}
                    closeOrderTime={closeOrderTime}
                />
                <Button onClick={() => {
                    chartRef.current.setNewInterval('1m');
                }}>1m</Button>
                <Button onClick={() => {
                    chartRef.current.setNewInterval('5m');
                }}>5m</Button>
                <Button onClick={() => {
                    chartRef.current.setNewInterval('15m');
                }}>15m</Button>
                <Button onClick={() => {
                    chartRef.current.setNewInterval('30m');
                }}>30m</Button>
                <Button onClick={() => {
                    chartRef.current.setNewInterval('1h');
                }}>1H</Button>
                <Button onClick={() => {
                    chartRef.current.setNewInterval('4h');
                }}>4H</Button>
                <Button onClick={() => {
                    chartRef.current.setNewInterval('1d');
                }}>1D</Button>
                <Button onClick={() => {
                    chartRef.current.setNewInterval('1w');
                }}>1W</Button>
            </Modal>

            <Modal
                title="Настройки входа в сделку"
                centered
                open={showSettings}
                onOk={() => setShowSettings(false)}
                onCancel={() => setShowSettings(false)}
                width={800}
            >
                {(settings && settings !== '') &&
                    <TextArea
                        rows={20}
                        style={{fontWeight: 400, fontSize: 16}}
                        value={JSON.stringify(JSON.parse(settings), undefined, 4)}
                    />
                }

            </Modal>

            <Modal
                title="Комментарий к сделке"
                centered
                open={showComments}
                onOk={() => writeNewComments()}
                onCancel={() => setShowComments(false)}
                width={800}
            >
                <TextArea
                    ref={commentTextAreaRef}
                    rows={20}
                    style={{fontWeight: 400, fontSize: 16}}
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    // defaultValue={comments}
                />
            </Modal>

            <Table
                // title={() => <h3>{title}</h3>}
                className={s.tableClass}
                loading={loading}
                size="small"
                // sticky={true}
                dataSource={data}
                columns={columns}
                bordered={true}
                pagination={paginationOptions}
                style={{fontWeight: 500}}
                summary={() => {
                    let totalOrders = 0;
                    let totalClosedOrders = 0;
                    let totalCost = 0;
                    let finRez = 0
                    let avgDuration = 0;

                    data.forEach(({pnl, unrealizePNL, entry_cost, exit_time, duration_in_hours}) => {
                        totalOrders++;

                        if (exit_time !== '0000-00-00' && exit_time !== '1970-01-01') {
                            totalClosedOrders++;
                        }

                        finRez += pnl + unrealizePNL;
                        totalCost += entry_cost;
                        avgDuration += duration_in_hours;
                    });

                    finRez = formatNumber(Math.round(finRez * 100) / 100);
                    totalCost = formatNumber(Math.round(totalCost * 100) / 100);
                    avgDuration = formatNumber(Math.round(avgDuration / totalClosedOrders));
                    totalCost = formatNumber(totalCost);

                    return (
                        <Table.Summary.Row style={{background: '#ececec'}}>
                            <Table.Summary.Cell index={1}
                                                align={'center'}><strong>Сделок</strong></Table.Summary.Cell>
                            <Table.Summary.Cell index={1}
                                                align={'center'}><strong>Всего: {totalOrders}</strong></Table.Summary.Cell>
                            <Table.Summary.Cell index={1}
                                                align={'center'}><strong>Закрыто: {totalClosedOrders}</strong></Table.Summary.Cell>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'}>
                                <Tooltip title="Сумма">
                                    <strong>Σ: {totalCost}</strong>
                                </Tooltip>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'}>
                                <Tooltip title="Среднее значение">
                                    <strong>⌀: {avgDuration}</strong>
                                </Tooltip>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'}>
                                <Tooltip title="Сумма">
                                    <strong>Σ: {finRez}</strong>
                                </Tooltip>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
                onRow={(record) => {
                    return {
                        // onClick: () => onChooseSignal(record.ID), // click row
                        onDoubleClick: () => {
                            // calculateProfit(record.symbol, record.entry_price, record.amount, record.side);
                            alert('strategy_setting_id = ' + record.strategy_setting_id);
                        }, // double click row
                        // onContextMenu: event => {
                        // }, // right button click row
                        // onMouseEnter: event => {
                        // }, // mouse enter row
                        // onMouseLeave: event => {
                        // }, // mouse leave row
                    };
                }}

            />
        </>);

}

OrdersTable.propTypes = {
    api: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    reloadDataHandler: PropTypes.func.isRequired,
};

export default OrdersTable;
