import axios from "axios";
import Cookies from 'js-cookie';

class APIClass {
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = process.env.REACT_APP_API_ENDPOINT;
        console.log(this.api_url);
    }

    init = () => {
        this.api_token = Cookies.get("ACCESS_TOKEN");

        let headers = {
            Accept: "application/json",
        };

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

    login = async (login, password) => {
        return await this.init().post("/login", {login: login, password: password}
        ).then((response) => {
            // console.log(response);
            if (response.data.login === 1) {
                Cookies.set('ACCESS_TOKEN', response.data.token);
                return {result: true};
            } else {
                return {result: false, error: 'Ошибочные данные для входа'};
            }
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message};
        });
    };

    logout = () => {
        Cookies.remove('ACCESS_TOKEN');
        this.api_token = '';
    };

    get_profit_by_exit_time = async (strategySelectValue) => {
        return await this.init().post("/profit_by_exit_time", {token: this.api_token, strategy_id: strategySelectValue}
        ).then((response) => {
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                this.logout();
                return {result: false, error: 'Сессия закончилась', action: 'logout'};
            }

            if (response.data.result !== undefined) {
                return {result: true, data: response.data.result};
            }
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    };

    get_profit_by_entry_time = async (strategySelectValue) => {
        return await this.init().post("/profit_by_entry_time", {token: this.api_token, strategy_id: strategySelectValue}
        ).then((response) => {
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                this.logout();
                return {result: false, error: 'Сессия закончилась', action: 'logout'};
            }

            if (response.data.result !== undefined) {
                return {result: true, data: response.data.result};
            }
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    };

    get_strategies_pnl_stats = async (strategySelectValue, startReportDate, endReportDate) => {
        return await this.init().post("/get_strategies_pnl_stats", {
                token: this.api_token,
                strategy_id: strategySelectValue,
                date_from: startReportDate,
                date_to: endReportDate
            }
        ).then((response) => {
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                this.logout();
                return {result: false, error: 'Сессия закончилась', action: 'logout'};
            }

            if (response.data.result !== undefined) {
                return {result: true, data: response.data.result};
            }
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    };

    get_top_symbols_profit_loss = async (strategySelectValue, startReportDate, endReportDate, sorter) => {
        return await this.init().post("/get_top_symbols_profit_loss", {
                token: this.api_token,
                strategy_id: strategySelectValue,
                date_from: startReportDate,
                date_to: endReportDate,
                sorter: sorter
            }
        ).then((response) => {
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                this.logout();
                return {result: false, error: 'Сессия закончилась', action: 'logout'};
            }

            if (response.data.result !== undefined) {
                return {result: true, data: response.data.result};
            }
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    };

    get_direction_pnl_stats = async (strategySelectValue, startReportDate, endReportDate) => {
        return await this.init().post("/get_direction_pnl_stats", {
                token: this.api_token,
                strategy_id: strategySelectValue,
                date_from: startReportDate,
                date_to: endReportDate
            }
        ).then((response) => {
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                this.logout();
                return {result: false, error: 'Сессия закончилась', action: 'logout'};
            }

            if (response.data.result !== undefined) {
                return {result: true, data: response.data.result};
            }
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    };

    get_symbol_pnl_stats = async (strategySelectValue, startReportDate, endReportDate) => {
        return await this.init().post("/get_symbol_pnl_stats", {
                token: this.api_token,
                strategy_id: strategySelectValue,
                date_from: startReportDate,
                date_to: endReportDate
            }
        ).then((response) => {
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                this.logout();
                return {result: false, error: 'Сессия закончилась', action: 'logout'};
            }

            if (response.data.result !== undefined) {
                return {result: true, data: response.data.result};
            }
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    };

    get_orders_journal = async (strategySelectValue, date_from, date_to, symbol, sorter, status) => {
        return await this.init().post("/get_orders_journal", {
                token: this.api_token,
                strategy_id: strategySelectValue,
                date_from: date_from,
                date_to: date_to,
                symbol: symbol,
                sorter: sorter,
                status: status
            }
        ).then((response) => {
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                this.logout();
                return {result: false, error: 'Сессия закончилась', action: 'logout'};
            }

            if (response.data.result !== undefined) {
                return {result: true, data: response.data.result};
            }
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    };

    set_new_comment = async (order_id, comment) => {

        comment = comment.replace(/(\r\n|\n|\r)/gm, " ");
        comment = comment.trim();

        return await this.init().post("/set_new_comment", {
                token: this.api_token,
                order_id: order_id,
                comment: comment
            }
        ).then((response) => {
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                this.logout();
                return {result: false, error: 'Сессия закончилась', action: 'logout'};
            }

            if (response.data.result !== undefined) {
                return {result: true, data: response.data.result};
            }
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    set_strategy_status = async (strategy_settings_id, status) => {

        return await this.init().post("/set_strategy_status", {
                token: this.api_token,
                strategy_settings_id: strategy_settings_id,
                status: status
            }
        ).then((response) => {
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                this.logout();
                return {result: false, error: 'Сессия закончилась', action: 'logout'};
            }

            if (response.data.result !== undefined) {
                return {result: true, data: response.data.result};
            }
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    get_strategy_list = async (strategySelectValue, symbol) => {
        return await this.init().post("/get_strategy_list", {
                token: this.api_token,
                strategy_id: strategySelectValue,
                symbol: symbol,
            }
        ).then((response) => {
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                this.logout();
                return {result: false, error: 'Сессия закончилась', action: 'logout'};
            }

            if (response.data.result !== undefined) {
                return {result: true, data: response.data.result};
            }
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    };

    get_strategy_orders = async (strategySettingsId) => {
        return await this.init().post("/get_strategy_orders", {
                token: this.api_token,
                strategy_id: strategySettingsId,
            }
        ).then((response) => {
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                this.logout();
                return {result: false, error: 'Сессия закончилась', action: 'logout'};
            }

            if (response.data.result !== undefined) {
                return {result: true, data: response.data.result};
            }
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    };
}

const API = new APIClass()

export default API