import axios from "axios";

export async function HistoricalData(symbol, interval, limit) {
    try {
        // Виконайте запит до Binance API
        const response = await axios.get(`https://fapi.binance.com/fapi/v1/klines?symbol=${symbol}&interval=${interval}&limit=${limit}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data: ', error);
    }
}

export async function getCurrentPrice(symbol) {
    try {
        symbol = symbol.replace('/', '').replace(':USDT', '');
        // Виконайте запит до Binance API для отримання поточної ціни
        const response = await axios.get(`https://fapi.binance.com/fapi/v1/ticker/price?symbol=${symbol}`);
        return response.data.price;
    } catch (error) {
        console.error('Error fetching current price: ', error);
    }
}
