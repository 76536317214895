import React, {useEffect, useState} from "react";

import PropTypes from "prop-types";

import {
    AreaChartOutlined,
    AndroidOutlined,
    LogoutOutlined,
    OrderedListOutlined
} from '@ant-design/icons';

import {Layout, Menu} from 'antd';

import Error from "../../components/Notifications/Error";
import StatisticsBlock from "../../components/StatisticsBlock";
import OrdersBlock from "../../components/OrdersBlock";

import {mobXStore} from "../../store";
import StrategyBlock from "../../components/StrategyBlock";

const {Content, Footer} = Layout;

function DashboardPage({api}) {

    const current_user = mobXStore.getKey('current_user');

    const [errorMsg, setErrorMsg] = useState('');

    const [showBlockContent, setShowBlockContent] = useState('0');

    useEffect(() => {
        setShowBlockContent('1');
    }, [])

    const topMenu = [
        {
            key: '1',
            label: `Статистика`,
            icon: <AreaChartOutlined/>,
        },
        {
            key: '2',
            label: `Сделки`,
            icon: <OrderedListOutlined/>,
        },
        {
            key: '3',
            label: `Управление стратегиями`,
            icon: <AndroidOutlined/>
        },
        // {
        //     key: '4',
        //     label: `Настройки пользователя`,
        //     icon: <UserOutlined/>,
        // },
        {
            key: '5',
            label: <>Выход [<b style={{color: '#516bf5'}}>{current_user}</b>]</>,
            icon: <LogoutOutlined/>,
        }
    ]


    const onClickTopMenu = (e) => {
        setShowBlockContent(e.key)

        switch (e.key) {
            case '1':
                break;
            case '2':
                break;
            case '3':
                break;
            case '4':
                break;
            case '5':
                mobXStore.showLoader(1000)
                mobXStore.setKey('isLogin', false);
                mobXStore.setKey('current_user', '');
                break;
            default:
                break
        }
    };

    return (
        <Layout style={{minHeight: "100vh", background: '#efefef'}}>
            <Error
                message={errorMsg}
            />
            <Menu
                theme="dark"
                onClick={onClickTopMenu}
                mode="horizontal"
                defaultSelectedKeys={['1']}
                items={topMenu}
            />
            <Layout
                style={{
                    background: '#efefef',
                    padding: '12px 4px 0px',
                }}
            >
                <Content
                    style={{
                        padding: '12px 12px 12px 12px',
                        background: '#ffffff',
                        borderRadius: '20px'
                    }}
                >
                    {(showBlockContent === '1') && (
                        <StatisticsBlock
                            api={api}
                            setErrorMsg={setErrorMsg}
                        />
                    )
                    }
                    {(showBlockContent === '2') && (
                        <OrdersBlock
                            api={api}
                            setErrorMsg={setErrorMsg}
                        />
                    )
                    }
                    {(showBlockContent === '3') && (
                        <StrategyBlock
                            api={api}
                            setErrorMsg={setErrorMsg}
                        />
                    )
                    }
                </Content>
                <Footer
                    style={{textAlign: 'center', background: '#efefef'}}
                >
                    LRYQ ©2024 Created by LRYQ
                </Footer>
            </Layout>
        </Layout>
    )
}

DashboardPage.propTypes = {
    api: PropTypes.object.isRequired,
};

export default DashboardPage;