import React from 'react';

import LoginForm from "../../components/LoginForm/LoginForm";
import PropTypes from "prop-types";

function LoginPage({api}) {

    return (
        <LoginForm api={api}/>
    );
}

LoginPage.propTypes = {
    api: PropTypes.object.isRequired,
};

export default LoginPage;