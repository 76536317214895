import React, {memo, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Button, Col, Input, Row, Select, Space, Tooltip} from "antd";

import {mobXStore} from "../../store";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import StrategyTable from "../StrategyTable";
import PropTypes from "prop-types";

const select_strategy_options = [
    {
        label: 'Все стратегии',
        value: 0,
    },
    {
        label: 'Pair Trading TF 1h',
        value: 1,
    },
    {
        label: 'Pair Trading TF 1D',
        value: 4,
    },
    {
        label: 'LinearRegression TF 1h',
        value: 2,
    },
    {
        label: 'VidkatShort TF 5m',
        value: 6,
    },
    {
        label: 'VidkatOne SHORT TF 5m',
        value: 7
    },
    {
        label: 'VidkatOne LONG TF 5m',
        value: 8
    },
    {
        label: 'VidkatOne SHORT TF 15m',
        value: 9
    },
    {
        label: 'VidkatOne LONG TF 15m',
        value: 10
    }
]

const select_strategy_orders_options = [
    {
        label: 'Все потоки',
        value: 0,
    },
    {
        label: 'Потоки в сделке',
        value: 1,
    },
    {
        label: 'Потоки в ожидании',
        value: 2,
    },
]

function StrategyBlock({api, setErrorMsg}) {

    const tableRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [errTimeout, setErrTimeout] = useState(0); // таймаут для вывода ошибки

    const [strategySelectValue, setStrategySelectValue] = useState(0);
    const [strategyOrdersSelectValue, setStrategyOrdersSelectValue] = useState(1);

    const [filterSymbol, setFilterSymbol] = useState('');

    const [lastErrMsg, setLastErrMsg] = useState('');

    const [strategyList, setStrategyList] = useState([]);

    useEffect(() => {

        // Обновляем данные каждую минуту
        // const timerRefresh = setTimeout(() => {
        //     get_strategy_list()
        // }, 60000);

        return () => {
            setErrorMsg('');
            if (errTimeout !== null) {
                clearTimeout(errTimeout);
                // clearTimeout(timerRefresh);
            }
        }
    }, []);

    useLayoutEffect(() => {
        get_strategy_list()
    }, [strategySelectValue, strategyOrdersSelectValue, filterSymbol]);


    // Выводим ошибку
    function handlerSetErrorMsg(error) {
        if (error !== '' && error !== lastErrMsg) {
            setLastErrMsg(error);
            setErrorMsg(error);

            setErrTimeout(setTimeout(() => {
                setErrorMsg('');
            }, 5000));
        }
    }

    // статистика PnL по времени выхода из позиции
    function get_strategy_list() {

        // Get Login Info
        setLoading(true);
        setErrorMsg('');

        api.get_strategy_list(strategySelectValue, filterSymbol).then((result) => {

            if (result.result) {

                if (strategyOrdersSelectValue === 1) {
                    result.data = result.data.filter((item) => {
                        return item.state === 3;
                    });
                } else if (strategyOrdersSelectValue === 2) {
                    result.data = result.data.filter((item) => {
                        return item.state !== 3;
                    });
                }

                setStrategyList(result.data);

                setTimeout(() => {
                    tableRef.current?.recalculateUnrealizedProfitHandler();
                    setLoading(false);
                }, 1);

                // setStrategyList(result.data);
            } else {
                setLoading(false);
                handlerSetErrorMsg(result.error);

                if (result.action === 'logout') {
                    // setErrorMsg('Сессия закончилась');
                    setTimeout(() => {
                        mobXStore.setKey('isLogin', false);
                    }, 1000);
                }
            }

        }).catch((error) => {
            setLoading(false);
            handlerSetErrorMsg(error.message);
        });
    }

    // статистика PnL по времени выхода из позиции
    function set_strategy_status(strategy_settings_id, status) {

        setLoading(true);
        setErrorMsg('');

        api.set_strategy_status(strategy_settings_id, status).then(() => {
            setLoading(false);
            get_strategy_list();
        }).catch((error) => {
            setLoading(false);
            handlerSetErrorMsg(error.message);
        });
    }

    return (
        <>
            <Row gutter={24} align={"middle"} justify={'center'} style={{background: '#efefef'}}>
                <Col>
                    <Space size={"large"} wrap={true} align={'center'}
                           style={{width: '100%', justifyContent: 'center'}}>

                        <Select
                            size={'large'}
                            defaultValue={strategySelectValue}
                            onChange={(value) => {
                                if (value !== null) {
                                    setStrategySelectValue(value);
                                }
                            }}
                            style={{
                                width: 250,
                            }}
                            options={select_strategy_options}
                        />

                        <Select
                            size={'large'}
                            defaultValue={strategyOrdersSelectValue}
                            onChange={(value) => {
                                if (value !== null) {
                                    setStrategyOrdersSelectValue(value);
                                }
                            }}
                            style={{
                                width: 250,
                            }}
                            options={select_strategy_orders_options}
                        />
                        <Input
                            size="large"
                            placeholder="поиск по Тикеру, Потоку или ID"
                            prefix={<SearchOutlined/>}
                            onChange={(e) => {
                                setFilterSymbol(e.target.value.toUpperCase());
                            }}
                            style={{
                                width: 300,
                            }}
                            value={filterSymbol}
                        />

                        <Tooltip title="Обновить данные">
                            <Button
                                size={'large'}
                                onClick={() => {
                                    get_strategy_list()
                                }}
                                icon={<ReloadOutlined/>}
                                style={{color: '#617af8'}}
                            />
                        </Tooltip>
                    </Space>
                </Col>
            </Row>
            <Row gutter={24} align={'middle'} justify={'center'}>
                <Col span={24}>
                    <StrategyTable
                        ref={tableRef}
                        reloadDataHandler={get_strategy_list}
                        api={api}
                        setStrategyStatus={set_strategy_status}
                        loading={loading}
                        data={strategyList}/>
                </Col>
            </Row>
        </>
    )
}

StrategyBlock.propTypes = {
    api: PropTypes.object.isRequired,
    setErrorMsg: PropTypes.func.isRequired,
};

export default memo(StrategyBlock);