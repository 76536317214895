export function calculateSMA(data, count) {
    const avg = function (data) {
        let sum = 0;
        for (const element of data) {
            sum += element.close;
        }
        return sum / data.length;
    };
    const result = [];
    for (let i = count - 1, len = data.length; i < len; i++) {
        const val = avg(data.slice(i - count + 1, i));
        result.push({time: data[i].time, value: val});
    }
    return result;
}

// format number
export function formatNumber(num) {
    return (num).toLocaleString(
        undefined, // leave undefined to use the visitor's browser
        // locale or a string like 'en-US' to override it.
        {minimumFractionDigits: 2}
    )
}
